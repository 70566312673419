<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="影院组织" name="organizationId" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

<!--					<a-form-item class="ui-form__item" label="所属影院" name="cinemaId" :rules="[{ required: true, message: '必选项不允许为空' }]">-->
<!--						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">-->
<!--&lt;!&ndash;							<a-select-option :value="''">全部</a-select-option>&ndash;&gt;-->
<!--							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>-->
<!--						</a-select>-->
<!--					</a-form-item>-->
          <a-form-item class="ui-form__item" label="发券影院" name="cinemaId" :rules="[{ required: true, message: '必选项不允许为空' }]">
            <a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
<!--              <a-select-option :value="''">全部</a-select-option>-->
              <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>

                    <a-form-item class="ui-form__item" label="会员手机号" name="phone">
						<a-input placeholder="请输入" maxLength="11" v-model:value="formState.phone"></a-input>
					</a-form-item>
				</a-row>

				<a-row v-show="isShow">
                    <a-form-item class="ui-form__item" label="兑换券号" name="barcode">
						<a-input placeholder="请输入" v-model:value="formState.barcode"></a-input>
					</a-form-item>

                    <a-form-item class="ui-form__item" label="会员昵称" name="nickname">
						<a-input placeholder="请输入" v-model:value="formState.nickname"></a-input>
					</a-form-item>

                    <a-form-item class="ui-form__item" label="创建时间" name="createTime">
						<a-range-picker :showTime="{ hideDisabledOptions: true, defaultValue:[moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')] }"  v-model:value="formState.createTime" />
					</a-form-item>

				</a-row>
				
				<a-row>
					<a-col :span="18">
						<span v-permission="['report_exchange_publish_export']">
							<ExportReport :disabled="!searchData.cinemaId" type="couponVolume" :searchData="searchData"></ExportReport>
						</span>
						<!-- <a-button v-permission="['report_exchange_publish_export']" type="primary" @click="onExport()" ghost>导出</a-button> -->
						<span v-if="!isShow" class="ui-showMore" @click="onDisplay">展开<Icon icon="DownOutlined"></Icon></span>
						<span v-else class="ui-showMore" @click="onDisplay">收起<Icon icon="UpOutlined"></Icon></span>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 1500 }" tableLayout="auto" >
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key == 'nickname'">
							<div>{{record.nickname || '--'}}</div>
						</template>
						
						<template v-if="column.key == 'isBind'">
							<div>{{['已绑定','已解绑'][record.isBind - 1]}}</div>
						</template>
						
						<template v-if="column.key == 'vaildTime'">
							<div>{{transDateTime(record.receiveStartTime)}}</div>
							<div>至</div>
							<div>{{transDateTime(record.receiveEndTime)}}</div>
						</template>

						<template v-if="column.key == 'createTime'">
							<div>{{transDateTime(record.createTime)}}</div>
						</template>

						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div>
											<a-menu-item @click="onEdit(record)">
												修改
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getExchangePublishReport } from '@/service/modules/report.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import moment from 'moment';
	import ExportReport from '@/components/exportReport/exportReport.vue';
	export default {
		components: {
			Icon,
			ExportReport
		},
		data() {
			return {
				loading: false,
				formState: {
					couponTypeArr: '1',
          cinemaId:''
				},
				showModal: false,
				list: [],
				columns: [{
					title: '发券影院',
					dataIndex: 'cinemaName'
				},{
          title: '消费影院',
          dataIndex: 'consumeCinemaName'
        },  {
					title: '券号',
					dataIndex: 'barcode'
				}, {
					title: '会员昵称',
					key: 'nickname'
				}, {
					title: '会员手机号',
					dataIndex: 'phone'
				}, {
					title: '状态',
					key: 'isBind'
				}, {
					title: '操作人',
					dataIndex: 'updateUserName'
				}, {
					title: '有效期',
					key: 'vaildTime',
				}, {
					title: '创建时间',
					key: 'createTime',
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				isEdit: false,
				id: 0,
				searchData: {},
				isShow: false,
				organizationList: [],
				cinemaAllList: [],
			}
		},
		created(){
			// this.onSearch();
			this.getOrganizationList();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
        this.searchData.grantCinemaId = this.searchData.grantCinemaId != '' ? this.searchData.grantCinemaId :undefined
        this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.pagination.total = 0;
				this.list = [];
				this.searchData = {};
				//this.onSearch();
			},
			onDisplay(){
				this.isShow = !this.isShow;
			},
			async getData(){
				if(this.searchData.createTime && this.searchData.createTime.length == 2){
					this.searchData.startTime = moment(this.formState.createTime[0]).unix();
					this.searchData.endTime = moment(this.formState.createTime[1]).unix();
				}
				delete this.searchData.createTime;
				const postData = {
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					...this.searchData
				}
				this.loading = true;
				try {
					let {data} = await getExchangePublishReport(postData);
					this.loading = false;
					this.list = data.list;
					this.pagination.total = data.totalCount;
				} catch (error) {
					this.loading = false;
				}
				
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					this.loading = false;
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						//this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = undefined;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
	.ui-batch{
		margin-right: 10px;
	}
	.ui-showMore{
        color: #6699ff;
        margin-left: 10px;
		cursor: pointer;
    }
</style>
